import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

const app = createApp(App).use(router);

app.provide('axios', axios.create({
	baseURL: 'https://api-laravel2.idoineformation.fr',
}))

app.mount('#app')
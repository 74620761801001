<script setup>

import {nextTick, ref} from 'vue';

const observer = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('animation');
      return;
    }
  });
}, {
  rootMargin: '-50% 0% -50% 0%',
});

const initAnimation = () => {
  nextTick(() => {
    const squares = document.querySelectorAll('.container');

    squares.forEach((element) => observer.observe(element));
  })
}

const showMenu = ref(false);

const mobile = window.screen.width < 900;

initAnimation();

</script>
<template>
  <div class="logo mobile" v-if="mobile">
    <img alt="logo idoine" src="./assets/images/logo.png">
  </div>
  <header :class="showMenu ? 'active' : ''">
    <div class="logo">
      <img alt="logo idoine" src="./assets/images/logo.png">
    </div>
    <nav>
      <router-link to="/" @click="showMenu = false">Accueil</router-link>
      <router-link to="/mission" @click="showMenu = false">Mission</router-link>
      <router-link to="/formations" @click="showMenu = false">Formations</router-link>
      <router-link to="/financer-sa-formation" @click="showMenu = false">Financer sa formation</router-link>
    </nav>
    <div class="contact-container">
      <router-link class="contact" to="/contact" @click="showMenu = false">Contact</router-link>
    </div>
  </header>
  <div :class="'burger-menu ' + (showMenu ? 'active' : '')" @click="showMenu = !showMenu">
    <svg id="hamburger" class="Header__toggle-svg" viewbox="0 0 60 40">
      <g stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round">
        <path id="top-line" d="M10,10 L50,10 Z"></path>
        <path id="middle-line" d="M10,20 L50,20 Z"></path>
        <path id="bottom-line" d="M10,30 L50,30 Z"></path>
      </g>
    </svg>
  </div>
  <router-view @initAnimation="initAnimation"/>
  <div class="footer">
    <nav>
      <router-link to="/" @click="showMenu = false">Accueil</router-link>
      <router-link to="/mission" @click="showMenu = false">Mission</router-link>
      <router-link to="/formations" @click="showMenu = false">Formations</router-link>
      <router-link to="/financer-sa-formation" @click="showMenu = false">Financer sa formation</router-link>
      <router-link to="/contact" @click="showMenu = false">Contact</router-link>
    </nav>
  </div>
  <div class="footer footer-2">
    <nav>
       <router-link target="_blank" href="https://www.linkedin.com/in/dominiquedura/"><img width="40px" src="@/assets/images/linkedin.png"></router-link>
    </nav>
  </div>
</template>

<style lang="scss">
.container{
  max-width: 100% !important;
}

.logo {
    position: relative;
    height: 100px;
    width: 100px;
    border-radius: 15px;
    background-color: $white;
    
    @media screen and (max-width: 900px){
      margin: 0 auto;
      height: 60px;
      width: 60px;
      margin-top: 10px;
      &.mobile {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 100;
      }
    }
    img {
      width: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

header {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  padding: 0 50px;
  z-index: 1000;
  width: -webkit-fill-available;

  @media screen and (max-width: 900px){
    width: 100vw;
    height: 100vh;
    background: linear-gradient($blue, $darkblue);
    color: white;
    flex-direction: column;
    padding: 0;
    gap: 15px;
    position: fixed;
    margin: 0;
    left: 101%;
    transition: left 1s ease;
    &.active {
      left: 0;
    }
  }

  .contact-container {
    @media screen and (max-width: 900px){
      width: 100%;
    }
  }

  a {
    text-decoration: none;
    color: $white;
    @media screen and (max-width: 900px){
      color: white;
      &:not(.contact) {
        font-size: 30px;
      }
    }
    &.router-link-active, &:hover {
      text-decoration: underline 2px $red;
      font-weight: 500;
    }
    &.contact {
      border: 2px $red solid;
      border-radius: 48px;
      padding: 13px 35px;
      text-decoration: none;
      @media screen and (max-width: 900px){
        width: 90%;
        font-weight: bold;
        display: block;
        text-align: center;
        padding: 13px 0;
        margin: auto;
        margin-bottom: 20px;
      }
      &.router-link-active, &:hover {
        background-color: $red;
        color: white;
      }
    }
  }

  nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 70%;
    font-size: 20px;

    @media screen and (max-width: 900px){
      flex-direction: column;
      align-items: start;
    }
  }
}

.burger-menu {
  display: none;
  @media screen and (max-width: 900px){
    display: block;
    position: fixed;
    z-index: 10000;
    right: 20px;
    top: 20px;
    height: 44px;
    text-align: right;
    display: block;
    cursor: pointer;
    width: 50px;
    svg {
      width: 100%;
      height: 100%;
      g {
        stroke: white;
        transition: stroke 1s ease;
      }
    }

    #top-line,
    #bottom-line,
    #middle-line {
      transform-box: fill-box;
      transform-origin: center;
    }

    &.active svg {
      g {
        stroke: black;
        transition: stroke 1s ease;
      }
      #top-line {
        animation: down-rotate 0.6s ease-out both;
      }
      #bottom-line {
        animation: up-rotate 0.6s ease-out both;
      }
      #middle-line {
        animation: hide 0.6s ease-out forwards;
      }
    }

    @keyframes up-rotate {
      0% {
        animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53);
        transform: translateY(0px);
      }
      30% {
        transform-origin: center;
        animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
        transform: translateY(-10px);
      }
      100% {
        transform-origin: center;
        transform: translateY(-10px) rotate(45deg) scale(0.9);
      }
    }

    @keyframes down-rotate {
      0% {
        animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53);
        transform: translateY(0px);
      }
      30% {
        transform-origin: center;
        animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
        transform: translateY(10px);
      }
      100% {
        transform-origin: center;
        transform: translateY(10px) rotate(-45deg) scale(0.9);
      }
    }

    @keyframes hide {
      29% {
        opacity: 1;
      }
      30% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
  }
}
.footer-2{
  height: 5vh !important;
}
.footer {
  width: 100%;
  height: 20vh;
  background-color: $darkblue;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  @media screen and (max-width: 900px){
    justify-content: start;
  }
  nav {
    margin-left: 30px;
    display: flex;
    @media screen and (max-width: 900px){
      flex-direction: column;
      gap: 10px;
    }
    gap: 40px;
    a {
      letter-spacing: 2px;
      color: $white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
